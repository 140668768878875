/* eslint-disable */
import type {AxiosResponse} from 'axios'
import {HpAlphaRegistrationService, RegistrationService} from './services'
import {digestData, getPersistedUser} from '@/utils/index'
import type {RegisterPublisherResponse, UserData} from "@/types/api";
import {EStatus} from "@/types/api";
// import { sendEmail } from "../utils/postmark"

export const mongoDbInterface = {
  test: async (): Promise<AxiosResponse> => {
    return HpAlphaRegistrationService.test()
  },

  getHoloports: async (): Promise<string[]> => {
    const user = getPersistedUser()

    if (user) {
      const result: AxiosResponse = await HpAlphaRegistrationService.findOne(user?.email || '')
      return result.data.holoportIds.map((hpIdData: { id: string }) => hpIdData.id)
    } else {
      return []
    }
  },

  getUserData: async (): Promise<UserData> => {
    const user = getPersistedUser()

    const result = await RegistrationService.findOne(user?.email || '')

    const {legalJurisdiction, givenNames, lastName, email, registrationCode} = result.data
    const {status} = registrationCode

    return {
      jurisdiction: legalJurisdiction,
      legalName: `${givenNames} ${lastName}`, // TODO: Discuss which name approach we're keeping - it's inconsistent across collections (fullName vs given + last)
      primaryEmail: email,
      status: EStatus.unregistered // TEMPORARY: force mock unregistered value for easy demo-ing
    }
  },

  registerAsPublisher: async (): Promise<RegisterPublisherResponse> => {
    const user = await getPersistedUser()

    if (user?.email) {
      // 1. get current registration data (for host)
      const agent = await RegistrationService.findOne(user.email)

      // 2. form registration data for publisher role and add to current record
      const createdAt = Date.now()
      const role = 'publisher'
      const hash = digestData({ ...agent.data, role, createdAt })
      const publisherEntry = { role, status: EStatus.new, tac: new Date(createdAt), code: hash }

      const result = await RegistrationService.updateOne(user.email, {
        push_params: { registrationCode: publisherEntry }
      })

      // TODO: Determine if/what email to send out at this stage
      // // send postmark email with registered template
      // const template = 'publisher-new-registration';
      // await sendEmail(email, template, {})

      // 3. return updated entry with role: publisher + status
      const { email, registrationCode } = result.data
      const { status } = registrationCode

      // TODO: Returm real data instead of mock
      return {
        email,
        status: EStatus.new // TEMPORARY: force mock value for easy demo-ing
      }
    } else {
      return {
        email: '',
        status: EStatus.unregistered
      }
    }
  },

  updateEmail: async () => {
    throw new Error('Not Implemented')
  }
}
/* eslint-enable */
