import { createWebHistory, createRouter, Router } from 'vue-router'
// import NotFound from '@/pages/NotFound.vue'
import RequestLink from '@/pages/RequestLink.vue'
import Springboard from '@/pages/SpringboardView.vue'
import { timeoutAsync } from '@/utils'
import { validateUser } from '@/utils/magicLink'

const kUserValidationTimeout = 2000

export const routes = [
  {
    path: '/request_link',
    name: 'RequestLink',
    component: RequestLink,
    meta: {
      guest: true
    }
  },
  {
    // Note: This path is called by magic-link api
    // The path that the magic link will redirect to.
    path: '/home',
    name: 'Springboard',
    component: Springboard,
    meta: {
      private: true
    }
  },
  {
    path: '/springboard',
    redirect: '/home'
  },
  // {
  //   path: '/404',
  //   component: NotFound
  // },
  // {
  //   path: '/:catchAll(.*)', // Unrecognized path automatically matches 404
  //   redirect: '/404'
  // },
  {
    path: '/',
    redirect: '/request_link'
  }
]

export function routerFactory(): Router {
  const router = createRouter({
    history: createWebHistory(),
    routes
  })

  router.beforeEach((to, _, next) => {
    const isLoggedIn = !!localStorage.getItem('user')

    const { magicCredential } = to.query

    if (to.matched.some((record) => record.meta.private) && !isLoggedIn) {
      Promise.race([validateUser(magicCredential), timeoutAsync(kUserValidationTimeout)])
        .then(() => {
          // Continue to springboard homepage if user validation succeeds
          next()
        })
        .catch((e) => {
          console.warn('Failed validation -> redirecting to login')
          localStorage.removeItem('user')
          // Redirect to request-link page when user validation fails
          next({
            name: 'RequestLink',
            params: {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              error: e
            }
          })
        })
    } else if (to.matched.some((record) => record.meta.guest)) {
      // Prevent the request-link page from showing when user is authorized
      if (magicCredential) {
        next({
          name: 'Springboard',
          query: to.query
        })
      } else {
        next()
      }
    } else {
      next()
    }
  })

  return router
}

const router = routerFactory()

export default router
