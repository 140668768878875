/* eslint-disable @typescript-eslint/naming-convention */
import type { AxiosResponse } from 'axios'
import httpCommon from './httpCommon'

class RegistrationServiceClass {
  async findOne(email: string): Promise<AxiosResponse> {
    return httpCommon.get(`/registrations/${email}`)
  }

  async updateOne(email: string, data: Record<string, unknown>): Promise<AxiosResponse> {
    return httpCommon.put(`/registrations/${email}`, data)
  }
}

export const RegistrationService = new RegistrationServiceClass()

class HpAlphaRegistrationServiceClass {
  async test(): Promise<AxiosResponse> {
    return httpCommon.get('/')
  }

  async findOne(email: string): Promise<AxiosResponse> {
    return httpCommon.get(`/hpalpharegistrations/${email}`)
  }
}

export const HpAlphaRegistrationService = new HpAlphaRegistrationServiceClass()

/* eslint-enable */
