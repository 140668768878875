import axios from 'axios'
import { SERVER_URI } from '@/utils/consts'

export default axios.create({
  baseURL: `${SERVER_URI}/api`,
  headers: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-type': 'application/json'
  }
})
