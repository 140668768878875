export enum EStatus {
  unregistered = 'unregistered',
  new = 'new',
  sent = 'sent',
  registered = 'registered'
}

export interface UserData {
  jurisdiction: string
  legalName: string
  primaryEmail: string
  status: EStatus
}

export interface RegisterPublisherResponse {
  email: string
  status: EStatus
}
